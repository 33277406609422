import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Article } from 'src/app/models/Article';
var ArticleViewerComponent = /** @class */ (function () {
    function ArticleViewerComponent(seo, eventEmitterService, activatedRoute, rest, route, router) {
        this.seo = seo;
        this.eventEmitterService = eventEmitterService;
        this.activatedRoute = activatedRoute;
        this.rest = rest;
        this.route = route;
        this.router = router;
        this.model = new Article;
        this.seo.addMetaTags();
    }
    ArticleViewerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var title, language;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                title = result['title'];
                //  this.rest.getArticleDetail(title).subscribe(rs => {
                //   if (rs.length > 0) {
                //     this.model = rs[0];
                //   } else {
                //     this.router.navigate(['/404']);
                //   }
                //  });
                if (this.eventEmitterService.subsVar === undefined) {
                    this.eventEmitterService.subsVar = this.eventEmitterService.
                        invokeArticleComponentFunction.subscribe(function (language) {
                        if (_this.activatedRoute.snapshot.url[0].path === 'article') {
                            _this.rest.getArticleDetail(title, language).subscribe(function (rs) {
                                if (rs.length > 0) {
                                    _this.model = rs[0];
                                }
                                else {
                                    _this.router.navigate(['/404']);
                                }
                            });
                        }
                    });
                }
                language = localStorage.getItem('selectedLang');
                this.rest.getArticleDetail(title, language).subscribe(function (rs) {
                    if (rs.length > 0) {
                        _this.model = rs[0];
                    }
                    else {
                        _this.router.navigate(['/404']);
                    }
                });
                return [2 /*return*/];
            });
        }); });
    };
    return ArticleViewerComponent;
}());
export { ArticleViewerComponent };
