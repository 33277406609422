import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var AboutComponent = /** @class */ (function () {
    function AboutComponent(rest, seo, route, sanitizer, eventEmitterService, router) {
        this.rest = rest;
        this.seo = seo;
        this.route = route;
        this.sanitizer = sanitizer;
        this.eventEmitterService = eventEmitterService;
        this.router = router;
        this.seo.addMetaTagsAbout();
    }
    AboutComponent.prototype.ngOnInit = function () {
        // this.rest.getAbout().subscribe(async rs => {
        //   if (rs.length > 0) {
        //     this.model = await this.sanitizer.bypassSecurityTrustHtml(rs[0].article_detail);
        //     this.title = await rs[0].article_title;
        //   } else {
        //     this.router.navigate(['/404']);
        //   }
        // });
        var _this = this;
        if (this.eventEmitterService.subsVar === undefined) {
            this.eventEmitterService.subsVar = this.eventEmitterService.
                invokeAboutComponentFunction.subscribe(function (language) {
                if (_this.router.url === '/about') {
                    _this.getAbout(language);
                }
            });
        }
        var language = localStorage.getItem('selectedLang');
        this.getAbout(language);
    };
    AboutComponent.prototype.getAbout = function (language) {
        var _this = this;
        this.rest.getAbout(language).subscribe(function (rs) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.sanitizer.bypassSecurityTrustHtml(rs[0].article_detail)];
                    case 1:
                        _a.model = _c.sent();
                        _b = this;
                        return [4 /*yield*/, rs[0].article_title];
                    case 2:
                        _b.title = _c.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    return AboutComponent;
}());
export { AboutComponent };
