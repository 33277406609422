import "hammerjs";
import { enableProdMode } from "@angular/core";
import { environment } from "./environments/environment";
import * as Sentry from "@sentry/angular";
import * as Tracing from "@sentry/tracing";
import * as __NgCli_bootstrap_1 from "./app/app.browser.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.browserTrack) {
    Sentry.init({
        dsn: environment.sentry_dsn,
        integrations: [
            new Tracing.BrowserTracing({
                tracingOrigins: ["*"],
                routingInstrumentation: Sentry.instrumentAngularRouting,
            }),
        ],
        tracesSampleRate: 1.0,
        environment: environment.production ? "production" : "development",
        beforeSend: function (event, hint) {
            console.info(event, hint);
            return event;
        },
    });
}
if (environment.production) {
    enableProdMode();
}
document.addEventListener("DOMContentLoaded", function () {
    __NgCli_bootstrap_2.platformBrowser()
        .bootstrapModuleFactory(__NgCli_bootstrap_1.AppBrowserModuleNgFactory)
        .catch(function (err) { return console.error(err); });
});
