import { OnInit } from "@angular/core";
import { Validators } from "@angular/forms";
var ApplicationEnquiryComponent = /** @class */ (function () {
    function ApplicationEnquiryComponent(rest, seo, spinner, formBuilder) {
        this.rest = rest;
        this.seo = seo;
        this.spinner = spinner;
        this.formBuilder = formBuilder;
        this.submitted = false;
        this.messageBlock = false;
        this.yahooUrl = "https://mail.yahoo.com/";
        this.seo.addMetaTags();
    }
    ApplicationEnquiryComponent.prototype.ngOnInit = function () {
        this.getImgCaptcha();
        // this.spinner.show();
        this.enquiryForm = this.formBuilder.group({
            email: [
                "",
                [
                    Validators.required,
                    Validators.pattern("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,10}$"),
                ],
            ],
            passport_number: [
                "",
                [Validators.required, Validators.pattern("[a-zA-Z0-9]*")],
            ],
            captcha_code: ["", [Validators.required, Validators.pattern("[0-9+-]+")]],
        });
    };
    Object.defineProperty(ApplicationEnquiryComponent.prototype, "nqryForm", {
        get: function () {
            return this.enquiryForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    ApplicationEnquiryComponent.prototype.applicationEnquiry = function (form) {
        this.submitted = true;
        if (form.valid) {
            console.log(form.value);
            // TODO: get mail url from backend
            this.eMailLink = form.value.email;
            // TODO: call verify captcha to get token
            // TODO: call application query api with response token
            this.messageBlock = true;
            // this.spinner.show();
            this.responseMsg =
                "Your APPLICATION ID is sent to your email. Pleaser check in your mail box.";
            // TODO: CALL real api to send request
            this.enquiryForm.reset();
            this.enquiryForm.clearValidators();
            this.submitted = false;
        }
    };
    ApplicationEnquiryComponent.prototype.backToForm = function () {
        this.messageBlock = false;
    };
    ApplicationEnquiryComponent.prototype.getImgCaptcha = function () {
        var _this = this;
        this.rest.getCaptchaImage().subscribe(function (data) {
            var reader = new FileReader();
            reader.addEventListener("load", function () {
                _this.captchaImg = reader.result;
            }, false);
            if (data) {
                reader.readAsDataURL(data);
            }
        }, function (error) {
            console.log(error);
        });
    };
    return ApplicationEnquiryComponent;
}());
export { ApplicationEnquiryComponent };
