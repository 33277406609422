import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { PopUpComponent } from "../pop-up/pop-up.component";
import { environment } from "src/environments/environment";
var InfoComponent = /** @class */ (function () {
    function InfoComponent(seo, rest, route, sanitizer, router, eventEmitterService, dialog) {
        this.seo = seo;
        this.rest = rest;
        this.route = route;
        this.sanitizer = sanitizer;
        this.router = router;
        this.eventEmitterService = eventEmitterService;
        this.dialog = dialog;
        this.seo.addMetaTags();
    }
    InfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.eventEmitterService.subsVar === undefined) {
            this.eventEmitterService.subsVar =
                this.eventEmitterService.invokeInfoComponentFunction.subscribe(function (language) {
                    if (_this.router.url === "/info") {
                        _this.getInfo(language);
                    }
                });
        }
        var language = localStorage.getItem(environment.selectedLang);
        this.getInfo(language);
        // this.route.params.subscribe( async (result) => {
        //   const title = result['title'];
        // });
    };
    InfoComponent.prototype.checkDuplicate = function () {
        var _this = this;
        var data = {
            countryOfPassport: 184,
            passportNumber: "519790550",
        };
        this.rest.CheckDuplicate(data).subscribe(function (result) {
            console.log("Check Duplicate: ", result);
            if (result.status == "success") {
                _this.router.navigate(["/review"]);
            }
            else {
                _this.dialog.open(PopUpComponent, {
                    data: result.data,
                    backdropClass: "backdropBackground",
                });
            }
        });
    };
    InfoComponent.prototype.getInfo = function (lang) {
        var _this = this;
        this.rest.getInfo(lang).subscribe(function (rs) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.sanitizer.bypassSecurityTrustHtml(rs[0].article_detail)];
                    case 1:
                        _a.model = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    return InfoComponent;
}());
export { InfoComponent };
