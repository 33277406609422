import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnInit, Renderer2, } from "@angular/core";
import { Validators } from "@angular/forms";
import { Person } from "src/app/models/formData";
import { environment } from "src/environments/environment";
import { MatDialog, } from "@angular/material/dialog";
var NewApplyComponent = /** @class */ (function () {
    function NewApplyComponent(window, localStorage, app, rest, router, seo, spinner, formBuilder, modalService, renderer, dialog) {
        this.window = window;
        this.localStorage = localStorage;
        this.app = app;
        this.rest = rest;
        this.router = router;
        this.seo = seo;
        this.spinner = spinner;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.renderer = renderer;
        this.dialog = dialog;
        this.imageEn = "../../../assets/images/form-preview-en.png";
        this.imageCn = "../../../assets/images/form-preview-cn.png";
        this.clientEmail = "";
        this.verifyCode = "";
        this.isSuccess = false;
        this.isNotSuccess = false;
        this.isShow = false;
        this.isEmpty = false;
        this.isCodeEmpty = false;
        this.model_pin = {};
        this.isGmail = false;
        this.isYahooMail = false;
        this.maxLength = 1;
        this.pin1 = "";
        this.pin2 = "";
        this.pin3 = "";
        this.pin4 = "";
        this.passportType = "";
        this.isOrdinaryPassport = false;
        this.person = new Person();
        this.countryOfPassport = "";
        this.citizens = "";
        this.days = 0;
        this.mDataArrayCountry = [];
        this.isMaintainer = environment.maintainerWeb;
        this.imageIndex = 0;
        this.imageViewerConfig = {
            zoomFactor: 0.5,
            containerBackgroundColor: "#000000",
            wheelZoom: true,
        };
        this.underMaintainance = false;
        this.openModal = false;
        this.seo.addMetaTags();
    }
    NewApplyComponent.prototype.onFocus = function (event) {
        // this.rest
        //   .updateUserSession({
        //     session_id: this.localStorage.getItem("session_id"),
        //     input_id: event.target.id,
        //     input_value: event.target.value,
        //   })
        //   .subscribe((res) => {
        //     console.log("Result Update Session when focus input:", res);
        //   });
    };
    NewApplyComponent.prototype.onBlur = function (event) {
        // this.rest
        //   .updateUserSession({
        //     session_id: this.localStorage.getItem("session_id"),
        //     input_id: event.target.id,
        //     input_value: event.target.value,
        //   })
        //   .subscribe((res) => {
        //     console.log("Result Update Session when unfocus input:", res);
        //   });
    };
    NewApplyComponent.prototype.getCountryList = function () {
        var _this = this;
        this.rest.getCountryPriceCalculator().subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.mDataArrayCountry = result;
                return [2 /*return*/];
            });
        }); });
    };
    NewApplyComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.rest.getMaintainance().subscribe(function (res) {
            if (res.status == "success") {
                _this.underMaintainance = true;
            }
            console.log(_this.underMaintainance);
        });
        this.selectLanguage = this.localStorage.getItem("selectedLang");
        this.getImageFromService();
        this.getCountryList();
        this.verifyForm = this.formBuilder.group({
            client_email: [
                "",
                [
                    Validators.required,
                    Validators.pattern("[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?"),
                ],
            ],
            verify_code: ["", Validators.required],
            passport_type: ["", Validators.required],
            country_passport: ["", Validators.required],
        });
        console.log(this.selectLanguage);
    };
    NewApplyComponent.prototype.ngAfterViewInit = function () {
        this.cop.nativeElement.focus();
    };
    NewApplyComponent.prototype.getImageFromService = function () {
        var _this = this;
        this.rest.getCaptchaImage().subscribe(function (image) {
            var reader = new FileReader();
            reader.addEventListener("load", function () {
                _this.captchaImage = reader.result;
            }, false);
            if (image)
                reader.readAsDataURL(image);
        }, function (error) {
            console.log(error);
        });
    };
    NewApplyComponent.prototype.onSubmitVerification = function (form) {
        var _this = this;
        console.log(form.valid, form.value);
        if (this.verifyCode &&
            this.clientEmail &&
            this.passportType == "ordinary" &&
            this.countryOfPassport) {
            var client_email_1 = form.value.client_email || this.clientEmail;
            this.rest
                .verifyCaptcha({
                verify_code: form.value.verify_code || this.verifyCode,
                client_email: ("" + client_email_1).replace(/(\/|\s)+/g, ""),
                country_of_passport: form.value.country_passport || this.countryOfPassport,
            })
                .subscribe(function (result) {
                var domain_email = ("" + client_email_1)
                    .replace(/(\/|\s)+/g, "")
                    .toLowerCase()
                    .split("@")[1];
                if (result.responseStatus === false) {
                    _this.isNotSuccess = true;
                    _this.message = "Security code is wrong!";
                }
                else {
                    _this.model_pin.verify_code = form.value.verify_code;
                    _this.model_pin.client_email = ("" + client_email_1).replace(/(\/|\s)+/g, "");
                    _this.your_email = ("" + client_email_1).replace(/(\/|\s)+/g, "");
                    _this.isMail = domain_email;
                    _this.isGmail = domain_email.includes("gmail");
                    _this.isYahooMail =
                        domain_email.includes("myyahoo") ||
                            domain_email.includes("yahoo") ||
                            domain_email.includes("ymail");
                    _this.localStorage.setItem("email", ("" + client_email_1).replace(/(\/|\s)+/g, ""));
                    _this.isSuccess = true;
                    _this.VerifyType = 2;
                }
            });
        }
        else {
            this.isNotSuccess = true;
            return;
        }
    };
    NewApplyComponent.prototype.verifyPin = function (form) {
        this.verifyPinNow();
    };
    NewApplyComponent.prototype.verifyPinNow = function () {
        var _this = this;
        var data = {
            pin1: this.pin1,
            pin2: this.pin2,
            pin3: this.pin3,
            pin4: this.pin4,
            client_email: ("" + this.model_pin.client_email).replace(/(\/|\s)+/g, ""),
            verify_code: this.model_pin.verify_code,
        };
        this.spinner.show();
        this.rest.verifyPin(data).subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (result.responseStatus === false) {
                    this.spinner.hide();
                    this.pin_isNotSuccess = true;
                    this.message_pin = "PIN is wrong!";
                }
                else {
                    this.spinner.hide();
                    this.localStorage.removeItem(environment.localAuthenInfo);
                    this.localStorage.setItem(environment.localAuthenInfo, JSON.stringify(result));
                    // this.rest
                    //   .updateUserSession({
                    //     email: this.your_email,
                    //     session_id: this.localStorage.getItem("session_id"),
                    //   })
                    //   .subscribe((res) => {
                    //     console.log("Result Update Session when focus input:", res);
                    //   });
                    this.router.navigate(["/application"]);
                }
                return [2 /*return*/];
            });
        }); });
    };
    NewApplyComponent.prototype.onKeyUp = function ($event, maxLength) {
        var element = $event.srcElement.nextElementSibling;
        var inputData = $event.srcElement.value.trim();
        if (this.pin1 !== "" &&
            this.pin2 !== "" &&
            this.pin3 !== "" &&
            this.pin4 !== "") {
            this.verifyPinNow();
        }
        if (element == null) {
            // check if its null
            return;
        }
        else if (inputData.length >= maxLength &&
            $event.code !== "Tab" &&
            $event.code !== "ShiftLeft" &&
            $event.code !== "ShiftRight") {
            this.pin_isNotSuccess = false;
            element.focus();
        }
    };
    NewApplyComponent.prototype.onActivate = function (event) {
        this.window.scroll(0, 0);
    };
    NewApplyComponent.prototype.openInbox = function () {
        var domain_email = this.clientEmail.toLowerCase().split("@")[1];
        var url = "";
        if (domain_email.includes("gmail")) {
            url = "https://mail.google.com/mail/u/0/#inbox";
        }
        else if (domain_email.includes("myyahoo") ||
            domain_email.includes("yahoo") ||
            domain_email.includes("ymail")) {
            url = "https://mail.yahoo." + (domain_email.split(".").slice(1).join(".") || "com");
        }
        else if (domain_email.includes("outlook") ||
            domain_email.includes("hotmail")) {
            url = "https://outlook.live.com/mail/0/";
        }
        else if (domain_email.split(".")[0].includes("qq")) {
            url = "https://mail.qq.com/";
        }
        window.open(url, "_blank");
    };
    NewApplyComponent.prototype.checkPassportType = function (event) {
        if (this.passportType == "ordinary") {
            this.isOrdinaryPassport = true;
        }
        else {
            this.isOrdinaryPassport = false;
        }
        if (!environment.production) {
            console.log("Select passport:", this.passportType);
            console.log(event.target);
        }
    };
    NewApplyComponent.prototype.onCountryOfPassportChange = function (selectedId, event) {
        if (selectedId !== "") {
            if (this.selectedCountry) {
                this.selectedCountry = selectedId;
            }
            var country = this.mDataArrayCountry.find(function (x) { return x.country_id == selectedId; });
            this.citizens = country.country_name;
            this.days = country.stay_day;
            if (country.is_except === 1) {
                event.srcElement.blur();
                event.preventDefault();
                this.openLg(this.content);
            }
            if (country.request_attach === 1) {
                this.person.is_request_doc = true;
            }
            else if (country.request_attach == 0) {
                this.person.is_request_doc = false;
            }
        }
        else {
            this.person.is_request_doc = false;
        }
    };
    NewApplyComponent.prototype.openLg = function (content) {
        this.modalService.open(content, { size: "lg", centered: true });
    };
    NewApplyComponent.prototype.clickOpenHelp = function () {
        this.selectLanguage = this.localStorage.getItem("selectedLang");
        this.overLay.nativeElement.style.display = "block";
        this.openModal = true;
    };
    NewApplyComponent.prototype.closeOpenHelp = function () {
        this.overLay.nativeElement.style.display = "none";
        this.openModal = false;
    };
    return NewApplyComponent;
}());
export { NewApplyComponent };
