import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
import * as i1 from "./rest.service";
import * as i2 from "@angular/router";
var CheckStatusApplyGuard = /** @class */ (function () {
    function CheckStatusApplyGuard(rest, router) {
        this.rest = rest;
        this.router = router;
        this.status = true;
    }
    CheckStatusApplyGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        this.rest.getIsCloseApply().subscribe(function (rs) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, localStorage.setItem('status', rs.status_can_apply)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
        return this.status;
    };
    CheckStatusApplyGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CheckStatusApplyGuard_Factory() { return new CheckStatusApplyGuard(i0.ɵɵinject(i1.RestService), i0.ɵɵinject(i2.Router)); }, token: CheckStatusApplyGuard, providedIn: "root" });
    return CheckStatusApplyGuard;
}());
export { CheckStatusApplyGuard };
