import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
var EventEmitterService = /** @class */ (function () {
    function EventEmitterService() {
        this.invokeTermConditionsComponentFunction = new EventEmitter();
        this.invokeFAQComponentFunction = new EventEmitter();
        this.invokeInfoComponentFunction = new EventEmitter();
        this.invokeAboutComponentFunction = new EventEmitter();
        this.invokeArticleComponentFunction = new EventEmitter();
    }
    EventEmitterService.prototype.onTermConditionComponentButtonClick = function (language) {
        this.invokeTermConditionsComponentFunction.emit(language);
    };
    EventEmitterService.prototype.onFAQComponentButtonClick = function (language) {
        this.invokeFAQComponentFunction.emit(language);
    };
    EventEmitterService.prototype.onInfoComponentButtonClick = function (language) {
        this.invokeInfoComponentFunction.emit(language);
    };
    EventEmitterService.prototype.onAboutComponentButtonClick = function (language) {
        this.invokeAboutComponentFunction.emit(language);
    };
    EventEmitterService.prototype.onArticleComponentButtonClick = function (language) {
        this.invokeArticleComponentFunction.emit(language);
    };
    EventEmitterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventEmitterService_Factory() { return new EventEmitterService(); }, token: EventEmitterService, providedIn: "root" });
    return EventEmitterService;
}());
export { EventEmitterService };
