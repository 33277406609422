import * as tslib_1 from "tslib";
import { isPlatformBrowser } from "@angular/common";
import { OnInit, } from "@angular/core";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
var CartComponent = /** @class */ (function () {
    function CartComponent(platformId, app, router, route, rest, formDataService, _location, location, sanitizer, countIncart, seo, _lightbox, translateService, config, modalService, localStorage) {
        this.platformId = platformId;
        this.app = app;
        this.router = router;
        this.route = route;
        this.rest = rest;
        this.formDataService = formDataService;
        this._location = _location;
        this.location = location;
        this.sanitizer = sanitizer;
        this.countIncart = countIncart;
        this.seo = seo;
        this._lightbox = _lightbox;
        this.translateService = translateService;
        this.modalService = modalService;
        this.localStorage = localStorage;
        this.node_static_url = environment.node_static_url;
        this.invoice_id = 0;
        this.total = 0;
        this.countTotal = 0;
        this.isEmptyCart = false;
        this.timeLeft = 0;
        this.isCheckOut = false;
        this.proofileCol = false;
        this._albumsPhoto = [];
        this._albumsPassport = [];
        this._albumsCover = [];
        this._albumProoFile = [];
        this.data = {
            session_id: "",
            input_id: "",
            input_value: "",
        };
        this.seo.addMetaTags();
        config.backdrop = "static";
        config.keyboard = false;
    }
    CartComponent.prototype.ngOnInit = function () {
        this.person = this.formDataService.getFormData();
        var localAuth = localStorage.getItem(environment.localAuthenInfo);
        if (localAuth) {
            var authInfo = JSON.parse(localAuth);
            if (authInfo) {
                this.person.token = authInfo.token;
                this.getData();
                this.selected_payment_method = "";
                this.selected_payment_method_text = "";
                this.selected_payment_active = "";
            }
        }
        else {
            this.router.navigate(["/apply"]);
        }
    };
    CartComponent.prototype.getData = function () {
        this.person.currentActiveForm = "3";
        this.countIncart.funcCountCart();
        // this.getPaymentMethods();
        this.getPersonInfo();
    };
    CartComponent.prototype.addMorePerson = function () {
        this.formData = this.formDataService.resetFormData();
        this.router.navigate(["/application"]);
        this.data.session_id = this.localStorage.getItem("session_id");
        this.data.input_id = "addMorePeople";
        this.data.input_value = "add_more_people";
        // this.rest.updateUserSession(this.data).subscribe((res) => {
        //   console.log("Result Update Session when focus input:", res);
        // });
    };
    // TODO: Use this instead of getPersonInfo
    // async getCartItems() {
    //   const cartItems = await this.rest.getCartItemsService();
    //   if (cartItems.length > 0) {
    //     this.mDataArrayPerson = cartItems;
    //     this.calculateTotal();
    //     this.invoice_id = cartItems[0].invoice_id;
    //     let totalPrice = 0;
    //     this.proofileCol = cartItems.some((item) => item.race == 304);
    //     for (const item of cartItems) {
    //       const srcPhoto = `${this.node_static_url}/${item.photo_name}`;
    //       const srcPassport = `${this.node_static_url}/${item.passport_name}`;
    //       const srcProofFile = `${this.node_static_url}/${item.proof_of_laotian}`;
    //       const srcCover = item.passport_cover_name;
    //       const captionPhoto = "Photo ";
    //       const captionPassport = "Passport ";
    //       const captionCover = "Cover ";
    //       const captionProofFile = "Proof of laotian ";
    //       const albumPhoto = {
    //         src: srcPhoto,
    //         caption: captionPhoto,
    //       };
    //       const albumPassport = {
    //         src: srcPassport,
    //         caption: captionPassport,
    //       };
    //       const albumProoFile = {
    //         src: srcProofFile,
    //         caption: captionProofFile,
    //       };
    //       const albumCover = {
    //         src: srcCover,
    //         caption: captionCover,
    //       };
    //       this._albumsPhoto.push(albumPhoto);
    //       this._albumsPassport.push(albumPassport);
    //       this._albumsCover.push(albumCover);
    //       this._albumProoFile.push(albumProoFile);
    //       totalPrice += Number(item.price);
    //       this.total = totalPrice;
    //     }
    //   } else {
    //     this.mDataArrayPerson = cartItems;
    //     this.isEmptyCart = true;
    //     this.total = 0;
    //   }
    // }
    CartComponent.prototype.getPaymentMethods = function () {
        var _this = this;
        this.rest.getPaymentMethods().subscribe(function (result) {
            _this.mPaymentMethod = result;
        });
    };
    CartComponent.prototype.calculateTotal = function () {
        this.countTotal = this.mDataArrayPerson.length;
    };
    CartComponent.prototype.testPay = function () {
        var payment = {
            invoice_id: 3155,
            payment_method: "cbs",
            pay_from: null,
        };
        console.log({ payment: payment });
    };
    // proceedCheckOut(e: any) {
    //   if (
    //     this.selected_payment_active === "no" ||
    //     this.selected_payment_method === ""
    //   ) {
    //     return;
    //   }
    //   Swal.fire({
    //     title: "Payment Confirmation",
    //     text:
    //       "You sure to make the payment via  " +
    //       this.selected_payment_method_text +
    //       " ?",
    //     type: "question",
    //     showCancelButton: true,
    //     confirmButtonColor: "#d33",
    //     cancelButtonColor: "#3085d6",
    //     cancelButtonText: "Cancel",
    //     confirmButtonText: "Pay",
    //   }).then((result) => {
    //     if (result.value) {
    //       this.data.session_id = this.localStorage.getItem("session_id");
    //       this.data.input_id = e.target.id;
    //       this.data.input_value = "to_payment_review";
    //       this.rest.updateUserSession(this.data).subscribe((res) => {
    //         console.log("Result Update Session when focus input:", res);
    //       });
    //       const data = {
    //         invoice_id: this.invoice_id,
    //         payment_method: this.selected_payment_method,
    //       };
    //       this.rest.payNow(data).subscribe(async (result) => {
    //         this.model = await result;
    //         setTimeout((_) => this.testFormEl.nativeElement.submit());
    //         setTimeout((_) => {
    //           this.getData();
    //         });
    //       });
    //     }
    //   });
    // }
    CartComponent.prototype.proceedContinue = function (e) {
        this.data.session_id = this.localStorage.getItem("session_id");
        this.data.input_id = e.target.id;
        this.data.input_value = "to_payment_review";
        // this.rest.updateUserSession(this.data).subscribe((res) => {
        //   console.log("Result Update Session when focus input:", res);
        // });
        this.router.navigate(["/continue-review"]);
    };
    CartComponent.prototype.deletePerson = function (index, application_id, first_name) {
        var _this = this;
        this.data.session_id = this.localStorage.getItem("session_id");
        this.data.input_id = "deletePerson";
        this.data.input_value = "delete_person";
        // this.rest.updateUserSession(this.data).subscribe((res) => {
        //   console.log("Result Update Session when focus input:", res);
        // });
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure to remove " + first_name + " ?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            cancelButtonText: "Cancel",
            confirmButtonText: "Remove",
        }).then(function (result) {
            if (result.value) {
                _this.rest.deletePerson(application_id).subscribe(function (rs) {
                    if (rs.status == "error") {
                        _this.isCheckOut = true;
                        // this.getCartItems();
                        _this.getPersonInfo();
                        _this.countIncart.funcCountCart();
                        return Swal.fire({
                            type: "error",
                            title: "" + rs.message,
                            showConfirmButton: true,
                        });
                    }
                    else {
                        _this.calculateTotal();
                        _this._albumsPhoto.splice(index, 1);
                        _this._albumsPassport.splice(index, 1);
                        if (rs.status == "success") {
                            // this.getCartItems();
                            _this.getPersonInfo();
                            _this.countIncart.funcCountCart();
                        }
                    }
                });
            }
        });
    };
    CartComponent.prototype.open = function (content) {
        this.startTimer();
        this.modalService.open(content, { centered: true });
    };
    CartComponent.prototype.openPhoto = function (index) {
        if (isPlatformBrowser(this.platformId)) {
            this._lightbox.open(this._albumsPhoto, index, { centerVertically: true });
        }
    };
    CartComponent.prototype.openPassport = function (index) {
        if (isPlatformBrowser(this.platformId)) {
            this._lightbox.open(this._albumsPassport, index, {
                centerVertically: true,
            });
        }
    };
    CartComponent.prototype.openProoFile = function (idx) {
        console.log(idx);
        if (isPlatformBrowser(this.platformId)) {
            this._lightbox.open(this._albumProoFile, idx, {
                centerVertically: true,
            });
        }
    };
    CartComponent.prototype.openCover = function (index) {
        if (isPlatformBrowser(this.platformId)) {
            this._lightbox.open(this._albumsCover, index, { centerVertically: true });
        }
    };
    CartComponent.prototype.closeImgPreview = function () {
        this._lightbox.close();
    };
    CartComponent.prototype.startTimer = function () {
        var _this = this;
        this.interval = setInterval(function () {
            _this.timeLeft += 20;
            if (_this.timeLeft === 100) {
                clearInterval(_this.interval);
                _this.modalService.dismissAll();
                // this.getCartItems();
                _this.getPersonInfo();
                _this.countIncart.funcCountCart();
            }
        }, 1000);
    };
    // TODO: use getCartItems instead for next version
    CartComponent.prototype.getPersonInfo = function () {
        var _this = this;
        this.rest.getPersonForPayment().subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var m_total, i, i, srcPhoto, _a, srcPassport, _b, srcCover, captionPhoto, captionPassport, captionCover, albumPhoto, albumPassport, albumCover;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(result.length > 0)) return [3 /*break*/, 10];
                        this.mDataArrayPerson = result;
                        this.calculateTotal();
                        this.invoice_id = result[0].invoice_id;
                        m_total = 0;
                        for (i = 0; i < result.length; i++) {
                            m_total += result[i].price;
                        }
                        this.total = Math.round(m_total * 100) / 100 || 0;
                        i = 0;
                        _c.label = 1;
                    case 1:
                        if (!(i < result.length)) return [3 /*break*/, 9];
                        if (!(result[i].version == "2")) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getBase64Image(result[i].photo_name, "photo")];
                    case 2:
                        _a = _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        _a = result[i].photo_name;
                        _c.label = 4;
                    case 4:
                        srcPhoto = _a;
                        if (!(result[i].version == "2")) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.getBase64Image(result[i].passport_name, "passport")];
                    case 5:
                        _b = _c.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        _b = result[i].passport_name;
                        _c.label = 7;
                    case 7:
                        srcPassport = _b;
                        srcCover = result[i].passport_cover_name;
                        this.mDataArrayPerson[i].photo_name = srcPhoto;
                        this.mDataArrayPerson[i].passport_name = srcPassport;
                        captionPhoto = "Photo ";
                        captionPassport = "Passport ";
                        captionCover = "Cover ";
                        albumPhoto = {
                            src: srcPhoto,
                            caption: captionPhoto,
                        };
                        albumPassport = {
                            src: srcPassport,
                            caption: captionPassport,
                        };
                        albumCover = {
                            src: srcCover,
                            caption: captionCover,
                        };
                        this._albumsPhoto.push(albumPhoto);
                        this._albumsPassport.push(albumPassport);
                        this._albumsCover.push(albumCover);
                        _c.label = 8;
                    case 8:
                        i++;
                        return [3 /*break*/, 1];
                    case 9: return [3 /*break*/, 11];
                    case 10:
                        this.mDataArrayPerson = result;
                        this.isEmptyCart = true;
                        this.total = 0;
                        _c.label = 11;
                    case 11: return [2 /*return*/];
                }
            });
        }); });
    };
    CartComponent.prototype.getBase64Image = function (imageName, image) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.rest
                            .getBase64ImageService(imageName, image)
                            .toPromise()];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result[image + "_name"]];
                }
            });
        });
    };
    return CartComponent;
}());
export { CartComponent };
