import * as tslib_1 from "tslib";
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { isNumber, toInteger } from './utils';
import * as moment from 'moment';
var NgbDateCustomParserFormatter = /** @class */ (function (_super) {
    tslib_1.__extends(NgbDateCustomParserFormatter, _super);
    function NgbDateCustomParserFormatter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.DT_FORMAT = 'DD-MM-YYYY';
        return _this;
    }
    NgbDateCustomParserFormatter.prototype.parse = function (value) {
        if (value) {
            var dateParts = value.trim().split('-');
            if (dateParts.length === 1 && isNumber(dateParts[0])) {
                return { day: toInteger(dateParts[0]), month: null, year: null };
            }
            else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
                return { day: toInteger(dateParts[0]), month: toInteger(dateParts[1]), year: null };
            }
            else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
                return { day: toInteger(dateParts[0]), month: toInteger(dateParts[1]), year: toInteger(dateParts[2]) };
            }
        }
        return null;
    };
    NgbDateCustomParserFormatter.prototype.format = function (date) {
        /*return date ?
            `${isNumber(date.day) ? padNumber(date.day) : ''}-${isNumber(date.month) ? padNumber(date.month) : ''}-${date.year}` :
            '';*/
        if (!date)
            return '';
        var mdt = moment([date.year, date.month - 1, date.day]);
        if (!mdt.isValid())
            return '';
        return mdt.format(this.DT_FORMAT);
    };
    return NgbDateCustomParserFormatter;
}(NgbDateParserFormatter));
export { NgbDateCustomParserFormatter };
