import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var RePayComponent = /** @class */ (function () {
    function RePayComponent(rest, route, router) {
        this.rest = rest;
        this.route = route;
        this.router = router;
        this.invoice_id = 0;
    }
    RePayComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var inv;
            return tslib_1.__generator(this, function (_a) {
                if ((params['id'])) {
                    inv = params['id'];
                    if (inv.length > 10) {
                        this.router.navigate(['/404']);
                    }
                    else {
                        this.checkRePay(params['id']);
                    }
                }
                else {
                    this.router.navigate(['/404']);
                }
                return [2 /*return*/];
            });
        }); });
    };
    RePayComponent.prototype.checkRePay = function (invoice_id) {
        var _this = this;
        var data = { 'payment_code': invoice_id };
        this.rest.checkRePay(data).subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (result.status === 'success') {
                    this.detail = result.repay.status === "Paid" ? "Was paid successful, invoice " + result.repay.invoice_id : "eVisa fee from invoice " + result.repay.invoice_id;
                    this.invoice_id = result.repay.invoice_id;
                    this.amount = result.repay.amount;
                    this.status = result.repay.status;
                    this.paid_date = result.repay.paid_date;
                }
                return [2 /*return*/];
            });
        }); });
    };
    RePayComponent.prototype.proceedCheckOut = function () {
        var _this = this;
        var data = { 'payment_code': this.invoice_id, 'payment_method': 'CBS' };
        this.rest.RePayNow(data).subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, result];
                    case 1:
                        _a.model = _b.sent();
                        setTimeout(function (_) { return _this.testFormEl.nativeElement.submit(); });
                        setTimeout(function (_) {
                        });
                        return [2 /*return*/];
                }
            });
        }); });
    };
    return RePayComponent;
}());
export { RePayComponent };
