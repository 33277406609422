import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
var PaySuccessComponent = /** @class */ (function () {
    function PaySuccessComponent(route, router, rest, spinner, app) {
        this.route = route;
        this.router = router;
        this.rest = rest;
        this.spinner = spinner;
        this.app = app;
        this.isEmptyCart = false;
        this.my_uuid = "";
        this.isCancelled = false;
    }
    PaySuccessComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var inv;
            return tslib_1.__generator(this, function (_a) {
                if (Number(params["id"])) {
                    inv = params["id"];
                    if (inv.length > 10) {
                        this.router.navigate(["/404"]);
                    }
                    else {
                        this.checkPayment(params["id"]);
                    }
                }
                else {
                    this.router.navigate(["/404"]);
                }
                return [2 /*return*/];
            });
        }); });
        this.route.queryParams.subscribe(function (query) {
            if (query.cancel == true || query.cancel == "true") {
                _this.isCancelled = true;
            }
        });
    };
    PaySuccessComponent.prototype.checkPayment = function (invoice_id) {
        var _this = this;
        var data = { invoice_id: invoice_id };
        this.rest.checkPayment(data).subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a, _b, _c, _d, _e, _f;
            return tslib_1.__generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, result.id];
                    case 1:
                        _a.my_uuid = _g.sent();
                        _b = this;
                        return [4 /*yield*/, result.status];
                    case 2:
                        _b.status = _g.sent();
                        _c = this;
                        return [4 /*yield*/, result.invoice_id];
                    case 3:
                        _c.invoice_id = _g.sent();
                        _d = this;
                        return [4 /*yield*/, result.message];
                    case 4:
                        _d.message = _g.sent();
                        _e = this;
                        return [4 /*yield*/, result.message_cn];
                    case 5:
                        _e.message_cn = _g.sent();
                        _f = this;
                        return [4 /*yield*/, result.message_fr];
                    case 6:
                        _f.message_fr = _g.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    return PaySuccessComponent;
}());
export { PaySuccessComponent };
