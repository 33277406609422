import * as moment from 'moment';
import * as i0 from "@angular/core";
var CookiesService = /** @class */ (function () {
    function CookiesService() {
    }
    CookiesService.prototype.setCookie = function (cname, cvalue, exhours) {
        var d = moment().add(exhours, 'hours').format('ddd, DD MMM YYYY HH:mm:ss[Z]');
        var expires = 'expires=' + d; // 'Thu, 18 Dec 2019 12:00:00 UTC';
        return document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    };
    CookiesService.prototype.getCookie = function (cname) {
        var name = cname + '=';
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    };
    CookiesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CookiesService_Factory() { return new CookiesService(); }, token: CookiesService, providedIn: "root" });
    return CookiesService;
}());
export { CookiesService };
