import * as tslib_1 from "tslib";
import { isPlatformBrowser } from "@angular/common";
import { OnInit, } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
var PaymentReviewComponent = /** @class */ (function () {
    function PaymentReviewComponent(platformId, app, router, rest, formDataService, countIncart, _lightbox, modalService, localStorage) {
        this.platformId = platformId;
        this.app = app;
        this.router = router;
        this.rest = rest;
        this.formDataService = formDataService;
        this.countIncart = countIncart;
        this._lightbox = _lightbox;
        this.modalService = modalService;
        this.localStorage = localStorage;
        this.node_static_url = environment.node_static_url;
        this.invoice_id = 0;
        this.total = 0;
        this.countTotal = 0;
        this.isEmptyCart = false;
        this.timeLeft = 0;
        this.model = {};
        this.isCheckOut = false;
        this._albumsPhoto = [];
        this._albumsPassport = [];
        this._albumsCover = [];
        this._albumProoFile = [];
        this.proofileCol = false;
        this.data = {
            session_id: "",
            input_id: "",
            input_value: "",
        };
    }
    PaymentReviewComponent.prototype.ngOnInit = function () {
        this.person = this.formDataService.getFormData();
        if (localStorage.getItem(environment.localAuthenInfo) != null) {
            var authInfo = JSON.parse(localStorage.getItem(environment.localAuthenInfo));
            if (authInfo) {
                this.person.token = authInfo.token;
                this.getData();
            }
        }
        else {
            this.router.navigate(["/apply"]);
        }
    };
    PaymentReviewComponent.prototype.getData = function () {
        this.person.currentActiveForm = "3";
        this.getPersonInfo();
        this.countIncart.funcCountCart();
    };
    PaymentReviewComponent.prototype.addMorePerson = function () {
        this.formData = this.formDataService.resetFormData();
        this.router.navigate(["/application"]);
        this.data.session_id = this.localStorage.getItem("session_id");
        this.data.input_id = "addMorePeople";
        this.data.input_value = "add_more_people";
        // this.rest.updateUserSession(this.data).subscribe((res) => {
        //   console.log("Result Update Session when focus input:", res);
        // });
    };
    PaymentReviewComponent.prototype.calculateTotal = function () {
        this.countTotal = this.mDataArrayPerson.length;
    };
    PaymentReviewComponent.prototype.backToCart = function (event) {
        this.data.session_id = this.localStorage.getItem("session_id");
        this.data.input_id = event.target.id;
        this.data.input_value = "back_to_cart";
        // this.rest.updateUserSession(this.data).subscribe((res) => {
        //   console.log("Result Update Session when focus input:", res);
        // });
        this.router.navigate(["/cart"]);
    };
    PaymentReviewComponent.prototype.open = function (content) {
        this.startTimer();
        this.modalService.open(content, { centered: true });
    };
    PaymentReviewComponent.prototype.openPhoto = function (index) {
        if (isPlatformBrowser(this.platformId)) {
            this._lightbox.open(this._albumsPhoto, index, { centerVertically: true });
        }
    };
    PaymentReviewComponent.prototype.openPassport = function (index) {
        if (isPlatformBrowser(this.platformId)) {
            this._lightbox.open(this._albumsPassport, index, {
                centerVertically: true,
            });
        }
    };
    PaymentReviewComponent.prototype.openCover = function (index) {
        if (isPlatformBrowser(this.platformId)) {
            this._lightbox.open(this._albumsCover, index, { centerVertically: true });
        }
    };
    PaymentReviewComponent.prototype.closeImgPreview = function () {
        this._lightbox.close();
    };
    PaymentReviewComponent.prototype.startTimer = function () {
        var _this = this;
        this.interval = setInterval(function () {
            _this.timeLeft += 20;
            if (_this.timeLeft === 100) {
                clearInterval(_this.interval);
                _this.modalService.dismissAll();
                _this.countIncart.funcCountCart();
                _this.getPersonInfo();
            }
        }, 1000);
    };
    PaymentReviewComponent.prototype.getPersonInfo = function () {
        var _this = this;
        this.rest.getPersonForPayment().subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var m_total, i, i, srcPhoto, _a, srcPassport, _b, srcCover, captionPhoto, captionPassport, captionCover, albumPhoto, albumPassport, albumCover;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(result.length > 0)) return [3 /*break*/, 10];
                        this.mDataArrayPerson = result;
                        this.calculateTotal();
                        this.invoice_id = result[0].invoice_id;
                        m_total = 0;
                        for (i = 0; i < result.length; i++) {
                            m_total += result[i].price;
                        }
                        this.total = Math.round(m_total * 100) / 100 || 0;
                        i = 0;
                        _c.label = 1;
                    case 1:
                        if (!(i < result.length)) return [3 /*break*/, 9];
                        if (!(result[i].version == "2")) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getBase64Image(result[i].photo_name, "photo")];
                    case 2:
                        _a = _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        _a = result[i].photo_name;
                        _c.label = 4;
                    case 4:
                        srcPhoto = _a;
                        if (!(result[i].version == "2")) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.getBase64Image(result[i].passport_name, "passport")];
                    case 5:
                        _b = _c.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        _b = result[i].passport_name;
                        _c.label = 7;
                    case 7:
                        srcPassport = _b;
                        srcCover = result[i].passport_cover_name;
                        this.mDataArrayPerson[i].photo_name = srcPhoto;
                        this.mDataArrayPerson[i].passport_name = srcPassport;
                        captionPhoto = "Photo ";
                        captionPassport = "Passport ";
                        captionCover = "Cover ";
                        albumPhoto = {
                            src: srcPhoto,
                            caption: captionPhoto,
                        };
                        albumPassport = {
                            src: srcPassport,
                            caption: captionPassport,
                        };
                        albumCover = {
                            src: srcCover,
                            caption: captionCover,
                        };
                        this._albumsPhoto.push(albumPhoto);
                        this._albumsPassport.push(albumPassport);
                        this._albumsCover.push(albumCover);
                        _c.label = 8;
                    case 8:
                        i++;
                        return [3 /*break*/, 1];
                    case 9:
                        this.total = m_total;
                        return [3 /*break*/, 11];
                    case 10:
                        this.mDataArrayPerson = result;
                        this.isEmptyCart = true;
                        this.total = 0;
                        _c.label = 11;
                    case 11: return [2 /*return*/];
                }
            });
        }); });
    };
    PaymentReviewComponent.prototype.onGetPersonInfo = function (mDataArrayPerson) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var m_total, i, i, srcPhoto, _a, srcPassport, _b, srcCover, captionPhoto, captionPassport, captionCover;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.mDataArrayPerson = mDataArrayPerson;
                        if (!(mDataArrayPerson.length > 0)) return [3 /*break*/, 10];
                        this.invoice_id = mDataArrayPerson[0].invoice_id;
                        m_total = 0;
                        for (i = 0; i < mDataArrayPerson.length; i++) {
                            m_total += mDataArrayPerson[i].price;
                        }
                        this.total = Math.round(m_total * 100) / 100 || 0;
                        i = 0;
                        _c.label = 1;
                    case 1:
                        if (!(i < mDataArrayPerson.length)) return [3 /*break*/, 9];
                        if (!(mDataArrayPerson[i].version == "2")) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getBase64Image(mDataArrayPerson[i].photo_name, "photo")];
                    case 2:
                        _a = _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        _a = mDataArrayPerson[i].photo_name;
                        _c.label = 4;
                    case 4:
                        srcPhoto = _a;
                        if (!(mDataArrayPerson[i].version == "2")) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.getBase64Image(mDataArrayPerson[i].passport_name, "passport")];
                    case 5:
                        _b = _c.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        _b = mDataArrayPerson[i].passport_name;
                        _c.label = 7;
                    case 7:
                        srcPassport = _b;
                        srcCover = mDataArrayPerson[i].passport_cover_name;
                        this.mDataArrayPerson[i].photo_name = srcPhoto;
                        this.mDataArrayPerson[i].passport_name = srcPassport;
                        captionPhoto = "Photo ";
                        captionPassport = "Passport ";
                        captionCover = "Cover ";
                        this._albumsPhoto.push({
                            src: srcPhoto,
                            caption: captionPhoto,
                        });
                        this._albumsPassport.push({
                            src: srcPassport,
                            caption: captionPassport,
                        });
                        this._albumsCover.push({
                            src: srcCover,
                            caption: captionCover,
                        });
                        _c.label = 8;
                    case 8:
                        i++;
                        return [3 /*break*/, 1];
                    case 9:
                        this.total = m_total;
                        return [3 /*break*/, 11];
                    case 10:
                        this.mDataArrayPerson = [];
                        this.isEmptyCart = true;
                        this.total = 0;
                        _c.label = 11;
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    PaymentReviewComponent.prototype.getBase64Image = function (imageName, image) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.rest
                            .getBase64ImageService(imageName, image)
                            .toPromise()];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result[image + "_name"]];
                }
            });
        });
    };
    return PaymentReviewComponent;
}());
export { PaymentReviewComponent };
