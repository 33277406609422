import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
import * as i1 from "./rest.service";
var CountCartService = /** @class */ (function () {
    function CountCartService(rest) {
        this.rest = rest;
        this.countCart = 0;
    }
    CountCartService.prototype.funcCountCart = function () {
        var _this = this;
        this.rest.getCountCart().subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(result != null)) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, result[0].countCart];
                    case 1:
                        _a.countCart = _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); });
    };
    CountCartService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CountCartService_Factory() { return new CountCartService(i0.ɵɵinject(i1.RestService)); }, token: CountCartService, providedIn: "root" });
    return CountCartService;
}());
export { CountCartService };
