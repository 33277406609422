import { FormData } from "../models/formData";
import * as i0 from "@angular/core";
var FormDataService = /** @class */ (function () {
    function FormDataService() {
        this.formData = new FormData();
    }
    FormDataService.prototype.getPerson = function () {
        // Return the Personal data
        var personal = {
            application_id: this.formData.application_id,
            imgPhoto: this.formData.imgPhoto,
            imgPass: this.formData.imgPass,
            imgPhotoName: this.formData.imgPhotoName,
            imgPassName: this.formData.imgPassName,
            imgCover: this.formData.imgCover,
            imgCoverName: this.formData.imgCoverName,
            hotel_doc: this.formData.hotel_doc,
            flight_doc: this.formData.flight_doc,
            third_visa_doc: this.formData.third_visa_doc,
            guarantor_doc: this.formData.guarantor_doc,
            firstName: this.formData.firstName,
            lastName: this.formData.lastName,
            gender: this.formData.gender,
            DOB: this.formData.DOB,
            countryBirth: this.formData.countryBirth,
            tel: this.formData.tel,
            email: this.formData.email,
            occupation: this.formData.occupation,
            resAddress: this.formData.resAddress,
            province: this.formData.province,
            countryCity: this.formData.countryCity,
            purposeOfTrip: this.formData.purposeOfTrip,
            addressDuringVisit: this.formData.addressDuringVisit,
            passportNumber: this.formData.passportNumber,
            issueDate: this.formData.issueDate,
            expiredDate: this.formData.expiredDate,
            countryOfPassport: this.formData.countryOfPassport,
            visaType: this.formData.visaType,
            portOfEntry: this.formData.portOfEntry,
            intendedDate: this.formData.intendedDate,
            vehicle: this.formData.vehicle,
            next_destination: this.formData.next_destination,
            currentActiveForm: this.formData.currentActiveForm,
            selectedGender: this.formData.selectedGender,
            selectedCountryBirth: this.formData.selectedCountryBirth,
            selectedCountryCity: this.formData.selectedCountryCity,
            selectedCountryOfPassport: this.formData.selectedCountryOfPassport,
            selectedvisaType: this.formData.selectedvisaType,
            selectedportOfEntry: this.formData.selectedportOfEntry,
            selectedPurpose: this.formData.selectedPurpose,
            token: this.formData.token,
            is_request_doc: this.formData.is_request_doc,
            partner_id: this.formData.partner_id,
            bank_statement_doc: this.formData.bank_statement_doc,
            race: Number(this.formData.race),
            family_side: this.formData.family_side,
            family_member_names: this.formData.family_member_names,
            family_relation: this.formData.family_relation,
            proof_of_laotian: this.formData.proof_of_laotian,
            first_action_date: this.formData.first_action_date,
        };
        return personal;
    };
    FormDataService.prototype.setPerson = function (data) {
        this.formData.application_id = data.application_id;
        this.formData.imgPhoto = data.imgPhoto;
        this.formData.imgPass = data.imgPass;
        this.formData.imgPhotoName = data.imgPhotoName;
        this.formData.imgPassName = data.imgPassName;
        this.formData.imgCover = data.imgCover;
        this.formData.imgCoverName = data.imgCoverName;
        this.formData.hotel_doc = data.hotel_doc;
        this.formData.flight_doc = data.flight_doc;
        this.formData.third_visa_doc = data.third_visa_doc;
        this.formData.guarantor_doc = data.guarantor_doc;
        this.formData.firstName = data.firstName;
        this.formData.lastName = data.lastName;
        this.formData.DOB = data.DOB;
        this.formData.gender = data.gender;
        this.formData.countryBirth = data.countryBirth;
        this.formData.tel = data.tel;
        this.formData.email = data.email;
        this.formData.occupation = data.occupation;
        this.formData.resAddress = data.resAddress;
        this.formData.province = data.province;
        this.formData.countryCity = data.countryCity;
        this.formData.purposeOfTrip = data.purposeOfTrip;
        this.formData.addressDuringVisit = data.addressDuringVisit;
        this.formData.passportNumber = data.passportNumber;
        this.formData.issueDate = data.issueDate;
        this.formData.expiredDate = data.expiredDate;
        this.formData.countryOfPassport = data.countryOfPassport;
        this.formData.visaType = '1';
        this.formData.portOfEntry = data.portOfEntry;
        this.formData.intendedDate = data.intendedDate;
        this.formData.vehicle = data.vehicle;
        this.formData.next_destination = data.next_destination;
        this.formData.currentActiveForm = data.currentActiveForm;
        this.formData.selectedGender = data.selectedGender;
        this.formData.selectedCountryBirth = data.selectedCountryBirth;
        this.formData.selectedCountryCity = data.selectedCountryCity;
        this.formData.selectedCountryOfPassport = data.selectedCountryOfPassport;
        this.formData.selectedvisaType = data.selectedvisaType;
        this.formData.selectedportOfEntry = data.selectedportOfEntry;
        this.formData.selectedPurpose = data.selectedPurpose;
        this.formData.token = data.token;
        this.formData.is_request_doc = data.is_request_doc;
        this.formData.partner_id = data.partner_id;
        this.formData.bank_statement_doc = data.bank_statement_doc;
        this.formData.race = Number(data.race);
        this.formData.family_side = data.family_side;
        this.formData.family_member_names = data.family_member_names;
        this.formData.family_relation = data.family_relation;
        this.formData.proof_of_laotian = data.proof_of_laotian;
        this.formData.first_action_date = data.first_action_date;
    };
    FormDataService.prototype.getCustomerImage = function () {
        // Return the Image data
        var image = {
            imgPhoto: this.formData.imgPhoto,
            imgPass: this.formData.imgPass,
            imgCover: this.formData.imgPass,
        };
        return image;
    };
    FormDataService.prototype.getFormData = function () {
        // Return the entire Form Data
        return this.formData;
    };
    FormDataService.prototype.resetFormData = function () {
        // Return the form data after all this.* members had been reset
        this.formData.clear();
        return this.formData;
    };
    FormDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FormDataService_Factory() { return new FormDataService(); }, token: FormDataService, providedIn: "root" });
    return FormDataService;
}());
export { FormDataService };
